<template>
  <structure-auth :shape="require('@/assets/images/reset_password/reset_password_shape_vector.svg')">
    <hs-loading v-if="isLoading" />
    <div v-else>
      <h3 data-testid="school-error-message" class="text-center py-3 my-3" v-if="schoolError">
        {{ $t('recover_password.error.school') }}
      </h3>

      <h3 data-testid="reset-error-message" class="text-center py-3 my-3" v-else-if="resetError">
        {{ $t('recover_password.error.reset') }}
      </h3>

      <ResetPasswordForm v-else @resetPassword="resetPassword" />
    </div>
  </structure-auth>
</template>

<script>
import { mapActions } from 'vuex';
import { hsLoading } from '@/components';
import StructureAuth from '@/auth/components/Auth';
import ResetPasswordForm from './ResetPasswordForm';

export default {
  components: {
    hsLoading,
    ResetPasswordForm,
    StructureAuth,
  },
  props: {
    token: {
      required: true,
      type: String,
    },
    subDomain: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      resetError: false,
      schoolError: false,
    };
  },
  methods: {
    ...mapActions('auth', ['updatePassword']),
    ...mapActions('school', ['getParams']),
    resetPassword(password) {
      this.isLoading = true;
      this.updatePassword({ token: this.token, password })
        .then(() => this.$router.push({ name: 'MenuHeaderLayout' }), () => (this.resetError = true))
        .finally(() => (this.isLoading = false));
    },
  },
  created() {
    this.isLoading = true;
    this.getParams({ subDomain: this.subDomain })
      .catch(() => (this.schoolError = true))
      .finally(() => (this.isLoading = false));
  },
};
</script>
