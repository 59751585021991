<template>
  <div>
    <hs-form @submit="resetPassword">
      <h5 class="auth-title">{{ $t('recover_password.form.title') }}</h5>
      <b-form-group>
        <label>{{ $t('recover_password.form.fields.password.name') }}</label>
        <hs-input
          id="recoverPassword_password"
          type="password"
          @blur="$v.form.password.$touch()"
          v-model="form.password"
          :state="!$v.form.password.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.password.$error">
            <hs-form-invalid-feedback :state="false">
              <span v-if="!$v.form.password.required">
                {{ $t('recover_password.form.validation.required') }}
              </span>
              <span v-if="!$v.form.password.minLength">
                {{ $t('recover_password.form.validation.password.minLength', { minLength: 6 }) }}
              </span>
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <b-form-group>
        <label>{{ $t('recover_password.form.fields.confirm_password') }}</label>
        <hs-input
          id="recoverPassword_confirmPassword"
          type="password"
          @blur="$v.form.confirmPassword.$touch()"
          v-model="form.confirmPassword"
          :state="!$v.form.confirmPassword.$error ? null : false"
        >
          <template slot="feedback" v-if="$v.form.confirmPassword.$error">
            <hs-form-invalid-feedback :state="false" v-if="!$v.form.confirmPassword.sameAsPassword">
              {{ $t('recover_password.form.validation.password.sameAs') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-input>
      </b-form-group>

      <hs-button block class="auth-form-button" variant="primary" type="submit" :disabled="$v.form.$invalid">
        {{ $t('recover_password.form.buttons.confirm_new_password') }}
      </hs-button>
    </hs-form>
  </div>
</template>

<script>
import { minLength, required, sameAs } from 'vuelidate/lib/validators';
import { hsForm } from '@/components';

export default {
  components: {
    hsForm,
  },
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
  methods: {
    resetPassword() {
      this.$emit('resetPassword', this.form.password);
    },
  },
};
</script>
